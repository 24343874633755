<template>
  <b-container>
    <div v-if="fetching">
      <Busy primary :size="3" text="Loading..." />
    </div>
    <div v-else>
      <ConsentListForm
        :newMode="isNew"
        :viewMode="isView"
        :consentList="consentList"
        :userEmails="allUserEmails"
        :connections="connectedEmails"
        :submitting="submitting"
        v-on:submit="submit"
        v-on:edit="isView = false"
        v-on:view="isView = true"
      />
    </div>
  </b-container>
</template>
<script>
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import ConsentListForm from "@/components/ConsentListForm";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import Busy from "@/components/Busy";

export default {
  name: "ConsentListData",
  created() {
    this.fetchData();
    EventBus.$on("deleteConnection", (email) => {
      this.deleteConnection(email);
    });
    EventBus.$on("updateConnections", (emails) => {
      this.connectedEmails = emails;
    });
    // this.originalConnectedEmails = this.connectedEmails;
  },
  data() {
    return {
      title: "Consent List Data",
      fetching: false,
      submitting: false,
      consentListId: "",
      isNew: false, // route param
      consentList: {},
      allUserEmails: [],
      connectedEmails: [], // populated from fetch, mutated on user input
      originalConnectedEmails: [], // ref for actual connections
      userData: [],
      isView: this.viewMode
    };
  },
  props: {
    viewMode: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Busy,
    ConsentListForm
  },
  methods: {
    async fetchData() {
      this.fetching = true;
      this.consentListId = this.$route.params.consentListId;
      this.isNew = this.consentListId === "new";
      if (this.isNew) this.isView = false;

      let client = await ApiHelper.http();

      // Get consent list
      if (!this.isNew) {
        await client
          .get(`${ApiHelper.endPoint()}consentlists/` + this.consentListId)
          .then((response) => {
            Console.log(response);
            this.consentList = response.data.consentList;
            this.consentList.deadline = FormatHelper.formatDateTimeCustom(
              this.consentList.deadline,
              "YYYY-MM-DD"
            );
            this.connectedEmails = this.originalConnectedEmails = response.data.connectedUsers;
          })
          .catch((e) => {
            this.fetching = false;
            this.showWarning("There was a problem getting consent list data.");
            Console.error(e);
          });
      }

      // Get all user emails
      if (this.allUserEmails.length < 1) {
        await client
          .get(`${ApiHelper.endPoint()}users?fields=email&fields=id`)
          .then((response) => {
            Console.log(response.data);
            this.userData = response.data.users;
            response.data.users.forEach((user) => {
              this.allUserEmails.push(user.email);
            });
            this.fetching = false;
          })
          .catch((e) => {
            this.fetching = false;
            this.showWarning("There was a problem getting user emails.");
            Console.error(e);
          });
      }
      this.fetching = false;
    },
    async submit() {
      this.submitting = true;

      // Convert deadline to local date time string
      let formattedConsentList = Object.assign({}, this.consentList);
      formattedConsentList.deadline += "T23:59:59.599Z";

      // Find user IDs
      let userIds = [];
      this.connectedEmails.forEach((email) => {
        let connectedUser = this.userData.filter((user) => user.email === email);
        connectedUser.forEach((user) => {
          if (!userIds.includes(user.userId)) {
            userIds.push(user.userId);
          }
        });
      });

      let client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}consentlists`, {
          consentList: formattedConsentList,
          userIds: userIds
        })
        .then((response) => {
          Console.log(response);
          this.submitting = false;

          if (this.isNew) {
            this.consentListId = response.data.consentListId;
            this.isNew = false;
            this.$router.replace({
              name: "consent-list-data",
              params: { consentListId: this.consentListId }
            });
          }
          this.fetchData();
          this.isView = true;
        })
        .catch((e) => {
          this.submitting = false;
          this.showWarning("There was a problem submitting details.");
          Console.error(e);
        });
    },
    async deleteConnection(email) {
      if (this.originalConnectedEmails.includes(email) & (email !== "")) {
        // Get user ID
        let userIds = this.userData
          .filter((user) => user.email === email)
          .map((user) => user.userId);

        let client = await ApiHelper.http();

        await client
          .delete(`${ApiHelper.endPoint()}consentlists/${this.consentListId}/user`, {
            data: { userIds: userIds }
          })
          .then((response) => {
            this.showSuccess("Disconnected successfully.");
            Console.log(response);
          })
          .catch((e) => {
            this.showWarning("There was a problem deleting connection.");
            Console.error(e);
          });
      }
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    showSuccess(message) {
      EventBus.$emit("show-toast", { message: message, variant: "success" });
    }
  }
};
</script>