<template>
  <div class="emailSelector">
    <b-input
      type="text"
      placeholder="Enter User's Email"
      v-if="!disabled"
      @input="onChange"
      v-model="search"
      @keyup.down="onArrowDown"
      @keyup.up="onArrowUp"
      @keyup.enter="onEnter"
    />
    <ul id="results" v-show="isOpen" class="results">
      <li
        v-for="(result, i) in results"
        :key="i"
        @click="setResult(result)"
        class="result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ result }}
      </li>
    </ul>
    <div v-if="submitted.length > 0">
      <table class="table col-sm-2">
        <tbody>
          <tr v-for="email in submitted" v-bind:key="email">
            <td>{{ email }}</td>
            <td>
              <font-awesome-icon
                v-if="!disabled"
                class="ml-2"
                :icon="['fas', 'times-circle']"
                @click="removeEmail(email)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style lang="scss">
@import "@/styles/common.scss";
.emailSelector .fa-times-circle {
  cursor: pointer;
  color: $color-red;
}
.results {
  padding: 0;
  margin: 0;
  border: 1px solid $color-grey-lighter4;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.result.is-active,
.result:hover {
  background-color: $color-grey-lighter1;
  color: white;
}
</style>
<script>
import { EventBus } from "@/components/eventbus";

export default {
  name: "EmailSelector",
  props: {
    connectedEmails: {
      type: Array,
      required: false,
      default: () => []
    },
    allEmails: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      submitted: [],
      isOpen: false,
      results: [],
      search: "",
      arrowCounter: 0
    };
  },
  created() {
    this.submitted = this.connectedEmails;
    this.originalConnections = this.connectedEmails;
  },
  methods: {
    onChange() {
      this.filterResults();
      this.isOpen = true;
    },
    filterResults() {
      // first uncapitalize all the things
      this.results = this.allEmails.filter((item) => {
        return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    setResult(result) {
      this.search = "";
      // prevent duplicates
      if (!this.submitted.includes(result)) {
        this.submitted.push(result);
      }
      this.isOpen = false;
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.search = "";
      // prevent duplicates
      let result = this.results[this.arrowCounter];
      if (!this.submitted.includes(result) && result !== undefined) {
        this.submitted.push(result);
      }
      this.isOpen = false;
      this.arrowCounter = -1;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    removeEmail(email) {
      this.submitted = this.submitted.filter((item) => item !== email);
      EventBus.$emit("deleteConnection", email);
    }
  },
  watch: {
    allEmails: function (val, oldValue) {
      if (val.length !== oldValue.length) {
        this.results = val;
      }
    },
    submitted: function () {
      EventBus.$emit("updateConnections", this.submitted);
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>
