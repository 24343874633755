<template>
  <div>
    <div v-if="newMode">
      <h3 class="mb-0">Create a New Consent List</h3>
      <p>
        A consent list is meant to hold up to 1000 consents.
        <br />Please consider making several consent lists if expecting high traffic.
      </p>
    </div>
    <h3 v-else-if="viewMode">View Consent List</h3>
    <h3 v-else>Edit Consent List</h3>

    <b-dropdown text="Actions" lazy class="mb-4">
      <b-dropdown-item :to="{ name: 'consent-lists' }">
        <font-awesome-icon :icon="['fas', 'user-check']" class="mr-1 text-muted space-right" />All
        Consent Lists
      </b-dropdown-item>
      <div v-if="!newMode">
        <b-dropdown-divider />
        <b-dropdown-item @click="editHandler" v-if="viewMode">
          <font-awesome-icon :icon="['far', 'edit']" class="mr-1 text-muted space-right" />Edit
          Consent List
        </b-dropdown-item>
        <b-dropdown-item @click="viewHandler" v-else>
          <font-awesome-icon :icon="['far', 'eye']" class="mr-1 text-muted space-right" />View
          Consent List
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'consents', params: { consentListId: consentList.consentListId } }"
        >
          <font-awesome-icon
            :icon="['fas', 'info-circle']"
            class="mr-1 text-muted space-right"
          />View Consents
        </b-dropdown-item>
      </div>
    </b-dropdown>

    <b-form @submit="submit" ref="form">
      <b-row>
        <b-col md="6">
          <!-- ID -->
          <b-form-group label="Consent List ID" label-for="id" v-if="!newMode">
            <b-form-input
              id="id"
              disabled
              v-model="consentList.consentListId"
              placeholder="Auto-Generated ID"
            ></b-form-input>
          </b-form-group>

          <!-- Name -->
          <b-form-group label="Name" label-for="name">
            <b-form-input
              id="name"
              required
              :disabled="viewMode"
              v-model="consentList.name"
              placeholder="Enter Consent List Name"
            ></b-form-input>
          </b-form-group>

          <!-- Deadline -->
          <b-form-group label="Connect Date" label-for="deadline">
            <b-form-input
              id="deadline"
              type="date"
              v-model="consentList.deadline"
              required
              :disabled="viewMode"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <!-- URL -->
        <b-col md="6">
          <b-form-group label="URL" label-for="url" v-if="!newMode">
            <b-input-group>
              <b-form-input
                disabled
                id="url"
                v-model="consentList.url"
                placeholder="Auto-Generated URL"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="primary"
                  v-b-tooltip.hover
                  title="Copy to Clipboard"
                  v-clipboard:copy="consentList.url"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                >
                  <font-awesome-icon
                    :icon="['far', 'clipboard']"
                    class="mr-1 text-muted space-right"
                    style="color: white !important"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <p v-if="copied">URL copied successfully.</p>
            <p v-if="copied === false">There was a problem copying URL.</p>
          </b-form-group>

          <!-- Description -->
          <b-form-group label="Description" label-for="description">
            <b-form-textarea
              id="description"
              rows="2"
              :disabled="viewMode"
              max-rows="6"
              v-model="consentList.description"
              placeholder="Enter Consent List Description"
            ></b-form-textarea>
          </b-form-group>

          <!-- Consent Type -->
          <b-form-group label="Consent Type" label-for="consentType" v-if="viewMode | newMode">
            <b-form-radio-group
              id="consentType"
              v-model="consentList.consentType"
              :disabled="viewMode"
              name="consentType"
              required
            >
              <b-form-radio name="consentType" value="optIn">Opt In</b-form-radio>
              <b-form-radio name="consentType" value="optOut">Opt Out</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!-- Connected Users -->
    <b-form-group :label="viewMode ? 'Connected Users' : 'Edit User Connections'">
      <EmailSelector :disabled="viewMode" :allEmails="userEmails" :connectedEmails="connections" />
    </b-form-group>
    <div class="text-right" v-if="!viewMode">
      <b-button class="mt-3" variant="primary" @click="submit" style="width: 9rem">
        <div v-if="submitting">
          <Busy light :size="1.5" :margin="0" />
        </div>
        <div v-else>Submit Details</div>
      </b-button>
    </div>
  </div>
</template>

<script>
import EmailSelector from "@/components/EmailSelector";
import Busy from "@/components/Busy";

export default {
  name: "ConsentListForm",
  props: {
    viewMode: Boolean,
    newMode: Boolean,
    submitting: Boolean,
    consentList: Object,
    userEmails: Array,
    connections: Array
  },
  data() {
    return {
      copied: null
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.checkValidity()) {
        this.$emit("submit", this.consentList);
      } else {
        this.$refs.form.reportValidity();
      }
    },
    editHandler() {
      this.$emit("edit");
    },
    viewHandler() {
      this.$emit("view");
    },
    copySuccess() {
      this.copied = true;
      setTimeout(() => (this.copied = null), 2000);
    },
    copyError() {
      this.copied = false;
      setTimeout(() => (this.copied = null), 2000);
    }
  },
  components: {
    EmailSelector,
    Busy
  }
};
</script>
